.content {
  color: var(--app-text-color);
  //
  margin-bottom: 184px;

  > h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #292d32;
    margin-bottom: 24px;
    padding: 0 1rem;
  }

  .listContent {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }

  .itemContent,
  .phoneItemContentUnSelect {
    width: calc((100% + 40px) / 3 - 40px);
    padding: 20px 24px 60px;
    box-shadow: 0px 2px 12px 0px #e3e6f3;
    border-radius: 16px;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 400px;

    .topContent {
      display: flex;

      .titleContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        > h3 {
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 20px;
          color: #725cff;
          line-height: 1.2;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .arrowContainer {
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    .otherContent {
      .desc {
        margin-top: 16px;
        margin-bottom: 32px;

        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 16px;
        color: rgba(12, 12, 12, 0.6);
        line-height: 1.125;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .subListContent {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .subItem {
          display: flex;
          align-items: center;
          gap: 5px;

          > span {
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #0c0c0c;
            line-height: 1.2;
            text-align: left;
            font-style: normal;
            text-transform: none;

            &:hover {
              color: #725cff;
            }
          }
        }
      }
    }
  }

  .phoneItemContentUnSelect {
    max-height: 60px;
    min-height: 60px;
    padding: 0 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .topContent {
      align-items: center;
      gap: 10px;
      flex: 1;

      .titleContent {
        align-items: center;
        flex-direction: row;
        gap: 5px;

        > h3 {
          font-size: 16px;
          color: #0c0c0c;
        }
      }
    }
  }

  .bottomListContent {
    justify-content: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    .listContent {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .itemContent,
    .phoneItemContentUnSelect {
      width: calc(100%);
      // padding: 16px 16px 40px;

      .otherContent {
        .desc {
          margin-top: 12px;
          margin-bottom: 24px;
        }

        .subListContent {
          gap: 16px;
        }
      }
    }

    .bottomListContent {
      margin-top: 24px;
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 154px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .content {
    margin-bottom: 119px;
  }
}

@media screen and (max-width: 576px) {
  .content {
    margin-bottom: 50px;

    > h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
}

